<template>
    <div class="con-wrap">
        <CarrotTitle title="Media Zone"></CarrotTitle>
        <div class="board">
            <div class="mt-40">
                <div>

                    <div>
                        <table class="table-row table-serach">
                            <colgroup>
                                <col width="180">
                                <col width="*">
                                <col width="180">
                                <col width="*">
                            </colgroup>
                            <tbody>
                                <tr>
                                    <th>기간</th>
                                    <td>
                                        <carrot-date-picker v-model="bbs.sdate" class="w-110px dp-inblock mr-10"></carrot-date-picker>
                                        ~
                                        <carrot-date-picker v-model="bbs.edate" class="w-110px dp-inblock ml-10"></carrot-date-picker>
                                    </td>
                                    <th>작성자</th>
                                    <td>
                                        <input type="text" class="w-100per" v-model="bbs.writer">
                                    </td>
                                </tr>
                                <tr>
                                    <th>제목</th>
                                    <td>
                                        <input type="text" class="w-100per" v-model="bbs.title">
                                    </td>
                                    <th>내용</th>
                                    <td>
                                        <input type="text" class="w-100per" v-model="bbs.content">
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <button class="btn-default float-right mt-10" @click="bbs.getList()">검색</button>
                        <div class="clear"></div>
                    </div>

                    <div class="mt-50 mb-20">
                        <select class="w-150px float-left" v-model="bbs.ca_name" @change="bbs.getList">
                            <option value="">카테고리 전체</option>
                            <option :value="irow" v-for="(irow, i) in bbs.ca_list" :key="i">{{ irow }}</option>
                        </select>
                        <button class="btn-default float-right" @click="bbs.showAdd()">새글 작성하기</button>
                        <div class="clear"></div>
                    </div>
                    
                    <ul class="media-list mt-35">

                        <!--샘플-->
                        <li class="list pointer" v-for="(irow, i) in bbs.list" :key="i">
                            <a class="link" @click="bbs.showView(irow.idx)">
                                <div class="imgbox">
                                    <img :src="irow.thumb" lt="thumnail" class="img">
                                </div>
                                <div class="textbox">
                                    <div class="tip">{{ irow.category }}</div>
                                    <div class="titbox">
                                        <span class="tit">{{ irow.title }}</span><!--@말줄임은 개발에서 해주세요.-->
                                        <span class="num" v-if="irow.cnt_cmt>0">({{ irow.cnt_cmt }})</span>
                                    </div>
                                    <div class="foot">
                                        <span class="txt fl">{{ irow.wdate }}</span>
                                        <div class="right">
                                            <span class="txt eye">{{ irow.hit }}</span>
                                            <span class="txt like">{{ irow.good }}</span>
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </li>
                        <!--//샘플-->

                        <li v-if="bbs.total==0" class="empty">
                            <span>검색 결과가 없습니다.</span>
                        </li>
                        
                        <!-- <li class="list">
                            <a href="#" class="link">
                                <div class="imgbox">
                                    <img src="@/assets/img/profile-01.jpg" alt="thumnail" class="img">
                                </div>
                                <div class="textbox">
                                    <div class="tip">과정 소개</div>
                                    <div class="titbox">
                                        <span class="tit">Zest팀의 Yoni님께서 만드신 당세미를 소개합니다!...</span>
                                        <span class="num">(20)</span>
                                    </div>
                                    <div class="foot">
                                        <span class="txt fl">2021.03.17</span>
                                        <div class="right">
                                            <span class="txt eye">23</span>
                                            <span class="txt like">7</span>
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </li> -->

                    </ul>
                    <CarrotPaging :total="bbs.total" :list_per_page="bbs.rows" v-model="bbs.page" @change="bbs.getList"></CarrotPaging>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
import { onMounted, onUnmounted, reactive } from 'vue'
import { useRouter } from 'vue-router'
import { useToast } from 'vue-toastification';
import axios from '@/plugins/axios.js'
import CarrotDatePicker from '@/components/common/CarrotDatePicker.vue'


export default {
    layout:"CARROTZone",
    components: {
        CarrotDatePicker
    },
    setup() {
        const router = useRouter()
        const toast  = useToast()

        const bbs = reactive({
            isMounted : false,
            ca_list : [],
            topfix  : false,
            page    : 1,
            rows    : 20,
            sdate   : "",
            edate   : "",
            title   : "",
            content : "",
            writer  : "",
            ca_name : "",
            list : [], total : 0,
            getList: () => {
                if( bbs.isMounted == false ) return;

                let params = {
                    page    : bbs.page,
                    sdate   : bbs.sdate,
                    edate   : bbs.edate,
                    title   : bbs.title,
                    content : bbs.content,
                    ca_name : bbs.ca_name,
                    writer  : bbs.writer
                }
                axios.get('/rest/carrotzone/mediazoneList', { params : params }).then((res) => {
                    if( res.data.err == 0 ){
                        bbs.list  = res.data.list;
                        bbs.total = res.data.total;

                        bbs.ca_list = res.data.ca_list;
                    } else {
                        // console.info(res.data.err_msg);
                        if( res.data.err_msg ) toast.error(res.data.err_msg);
                    }
                })
            },
            showView: (idx)=>{
                // location.href = "MediaZoneView/" + idx;
                router.push({
                    name   : 'CARROTZone-MediaZoneView-idx',
                    params : { idx:idx }
                });
            },
            showAdd: () => {
                // location.href = "MediaZoneAdd";
                router.push('MediaZoneAdd');
            }
        });
        
        onMounted(() => {
            // Mounted
            bbs.isMounted = true;

            bbs.getList();
        });

        onUnmounted(() => { 
            // UnMounted
        });
        return {bbs};
    }
}
</script>

<style lang="scss" scoped>
</style>